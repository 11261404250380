.button-link {
  display: inline-block;
  background-color: #1b75bb;
  /* Example button color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 0.3rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-link:hover,
.button-link:focus {
  background-color: #0B3D59;
  color: white;
}

.attention {
  font-weight: 700;
}



#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  /* This pushes the footer to the bottom */
}