ul {
    padding-left: 0 !important;
}

.table-container {
    display: flex;
    justify-content: center;
    align-items:  center;
    margin-top: 20px;
}
table {
    border-collapse: collapse;
    width: 100%;
    /*height: 100%;*/
}
th,td {
    padding: 8px 12px;
    text-align: center;
    font-size: 16px;
}
th {
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}
th:nth-child(0), td:nth-child(0) {
    background-color: transparent;
}
td:nth-child(2), td:nth-child(3) {
    background-color: #174a6d;
    color: #ffffff;
}
td:nth-child(4) {
    background-color: #3d5a6f;
    color: #ffffff;
}
 td:nth-child(1) {
    background-color: #567a8d;
    color: #ffffff;
}

tr {
    background-color: #f2a541;
    color: #ffffff; 
}

td:nth-child(2):hover, td:nth-child(3):hover, td:nth-child(4):hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}

.reasons {
    ul {
        padding-left: 0;
    }
    
}

h5 {
    color: #1b75bb;
}


.logo-container img {
    height: 100px;
}

.card-header {
    background-color: #1b75bb !important;

}

@media (max-width: 768px) {
    table{
        margin-left: 1rem !important;
    }
    th, td {
        padding: 4px;
        font-size: 10px;
    }
}