.programme {
  font-family: Arial, sans-serif;
  background-color: white;
  z-index: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #333;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates a 3-column grid */
  gap: 20px;
  margin-top: 40px;
}

@media (min-width: 992px) {
  .cards {
    gap: 40px;
  }
}

@media (min-width: 1200px) {
  .cards {
    gap: 20px;
  }
  .programme {
    margin-top: 4rem;
  }
}

.card {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Adjusts spacing to push the button to the bottom */
}

.interactive-card{
  text-decoration: none;
}

.card-large {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  grid-column: 1 / 2; /* Occupies the first column */
  grid-row: 1 / 3; /* Spans the first and second row */
}

.card-large h3{
  margin-top: 8px;
  margin-bottom: 10px;
  color: black;
}

.card-large img {
  height: 100%; /* Adjusted to make images smaller */
  margin-bottom: 20px; /* Adds space between the image and the text */
  align-self: center; /* Centers the image within the card */
  max-height: 150px; /* Example max-width to prevent images from becoming too large */
}

.card-large {
  /* Your existing styles */
  padding: 20px; /* Ensure there's padding around the content */
}

.card-large ul {
  text-align: left; /* Align the text of the list to the left */
  padding-left: 20px; /* Add some padding to indent the list */
  margin-top: 10px; /* Add some space above the list */
}

.card-large li {
  margin-bottom: 5px; /* Add some space between list items */
  list-style-type: disc; /* Use disc style for bullets, or choose another */
  color: #333; /* Darker text color for better readability */
  font-size: 16px; /* Adjust the font size as needed */
}

.card ul {
  text-align: left; /* Align the text of the list to the left */
  padding-left: 20px; /* Add some padding to indent the list */
  margin-top: 10px; /* Add some space above the list */
}

.card li {
  margin-bottom: 5px; /* Add some space between list items */
  list-style-type: disc; /* Use disc style for bullets, or choose another */
  color: #333; /* Darker text color for better readability */
  font-size: 16px; /* Adjust the font size as needed */
}

.card-large button {
  border-radius: 0.3rem;
}

.card img, .card a {
  height: 100%; /* Adjusted to make images smaller */
  margin-bottom: 20px; /* Adds space between the image and the text */
  align-self: center; /* Centers the image within the card */
  max-height: 150px; /* Example max-width to prevent images from becoming too large */
  text-decoration: none;
}

.card h4 {
  margin-top: 8px;
  margin-bottom: 10px;
  color: black;
}

.card p {
  margin-bottom: 15px;
  font-size: 16px;
  color: black;
  flex-grow: 1; /* Allows the paragraph to take up any available space */
}

button {
  background-color: #1b75bb;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 0.3rem;
}

button:hover {
  background-color: #0B3D59;
}

.navigation-button-homepage {
  width: 70%;
  margin: auto;
  margin-top: 1rem;
  line-height: 3rem;
  font-weight: bold;
  border-radius: 0.3rem;
  color: white;
  background-color: #1b75bb;
  text-decoration: none;
  text-align: center;
  display: none;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3); /* Jemný tieň */
}

@media (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr; /* Adjusts to a single column on smaller screens */
  }

  .card-large {
    grid-column: 1;
    grid-row: 1;
  }

  .navigation-button-homepage {
    display: block;
  }
}