.width-66 {
  width: 60%;
}

.width-33 {
  width: 27%;
}

.shadow-small {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.height-80 {
  min-height: 50vh;
  max-height: 50vh;
}

.card-company {
  min-width: 50%;
  max-width: 50%;
}

.container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 6vw;
  padding-right: 6vw;
  padding-bottom: 0px;
}

.container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-background {
  background-color: white;
}

.card-subtitle {
  text-align: center;
  padding-top: 2rem;
}

.card-subtitle h4{
  font-size: 18px;
}

.card-title {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: medium;
}

.card-description {
  text-align: justify;
  font-weight: lighter;
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}


.table-companies {
  border: 'solid 1px blue';
  margin-bottom: 3rem;
}

/* Hide mobile-version by default and show container-row */
.mobile-version {
  display: none;
}
.buttonform1{
  display: none;
}

.container-row {
  display: block; /* or 'flex' if it's a flex container */
}

#reference-to-stall{
  margin-left: 1rem;
  color: white;
}

/* When the screen size is 767px or less, hide container-row and show mobile-version */
@media (max-width: 767px) {
  .container-row {
    display: none;
  }

  .mobile-version {
    display: block; /* Adjust this as needed, e.g., 'flex', 'grid' */
  }

  .mobile-planek{
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .mobile-planek1{
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}

@media (max-width: 1399px) {
  .buttonform{
    display: none;
  }

  .buttonform1{
    display: block;
  }

  .choose-options
  {
    opacity: 0;
    z-index: -10;
  }
}

.company-row {
  background-color: #f0f0f0;
  margin-bottom: 8px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: background-color 0.3s;
}

.company-row:hover {
  background-color: #e0e0e0;
}

.info-container {
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: none; /* Start hidden */
}

.info-container p, .info-container a {
  margin: 8px 0;
}

.info-container a {
  display: inline-block;
  background-color: #007bff;
  color: #ffffff;
  padding: 8px 12px;
  text-decoration: none;
  border-radius: 4px;
}

.mobile-jobfair-plan{
  display: none;
}

.right-hint{
  margin-top: 1rem;
  margin-right: 0.5rem; /* Add some left margin */
  margin-bottom: -2rem; /* Remove some bottom margin */
  text-align: right;
}

.centered-hint{
  margin-bottom: -1rem;
  margin-right: 0.5rem; /* Add some left margin */
  text-align: center;
}

.mobile-buttonform{
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}

.companiesContainer{
  margin-left: 0.5rem;
}

.search-mobile{
  padding: 10px;
  border: 1px solid #636363;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

}

.input-mobile{
  text-align: center;
}

.title{
  margin-top: 50px;
}


.svg_img{
  width: 70%;
  margin-right: 30rem;
}